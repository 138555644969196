<template>
  <FolderSettings mode="add" :route-on-action="{ name: 'folders_index' }"></FolderSettings>
</template>

<script>
import FolderSettings from '../../components/folder/FolderSettings'

export default {
  components: {
    FolderSettings: FolderSettings
  }
}
</script>
