<template>
  <div id="folder-add">
    <Headline />
    <FolderAdd />
  </div>
</template>

<script>
import Headline from '../../components/app/Headline'
import FolderAdd from '../../components/folder/FolderAdd'

export default {
  components: {
    Headline,
    FolderAdd
  }
}
</script>
