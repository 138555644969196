<template>
  <div>
    <SettingsEdit
      :settings="currentFolder"
      @save="folderChanged"
      @cancel="backToRoute"
      :show-settings="showSettings"
      :active-tab="activeTab"
      type="folder"
      ref="settings"
      :cancelable="true"
    />
  </div>
</template>

<script>
import { rules } from '@/validation/rules'
import SettingsEdit from '@/components/settings/SettingsEdit.vue'

export default {
  components: { SettingsEdit },
  props: {
    mode: {
      String,
      default: 'edit'
    },
    folderId: {
      Number
    },
    routeOnAction: {
      Object,
      default: () => null
    }
  },
  created() {
    if (this.mode === 'add' || this.folderId == null) {
      this.currentFolder = {}
      return
    }

    // mode edit
    this.$store.dispatch('folder/fetch', this.folderId).then(folder => {
      this.currentFolder = folder
      if (this.currentFolder.captcha) {
        this.currentFolder.captcha = this.currentFolder.captcha.toString()
      }

      this.currentFolder.use_settings = this.currentFolder.use_settings === 1
      this.activeTab = this.currentFolder.use_settings ? 0 : 1
    })
  },
  data() {
    return {
      tab: null,
      currentFolder: null,
      showSettings: false,
      activeTab: this.mode === 'add' ? 1 : 0,
      rules: rules,
      errors: null
    }
  },
  watch: {
    'currentFolder.use_settings': {
      handler(useSettings) {
        this.showSettings = useSettings
      }
    }
  },
  methods: {
    folderChanged(updatedFolder) {
      let storeAction = 'folder/store'
      let data = { ...this.getSaleSettingFields(updatedFolder), ...this.getGeneralSettingsFields(updatedFolder) }

      if (this.mode === 'edit') {
        storeAction = 'folder/update'
        data = {
          folder: this.currentFolder.id,
          folderData: data
        }
      }
      this.$store
        .dispatch(storeAction, data)
        .then(() => {
          this.$store.dispatch('snackbar/showSuccessMessage', { message: this.$t('folder_settings.saved_' + this.mode) })
          this.backToRoute()
        })
        .catch(() => this.$parent.$emit('api_error'))
    },
    getGeneralSettingsFields(updatedFolder) {
      return {
        name: updatedFolder.name,
        useimprint: updatedFolder.useimprint,
        show_domainname: updatedFolder.show_domainname,
        note: updatedFolder.note ?? '',
        use_settings: updatedFolder.use_settings ? 1 : 0
      }
    },
    getSaleSettingFields(updatedFolder) {
      let saleSettings = {}
      saleSettings.forsale_type = updatedFolder.forsale_type

      switch (updatedFolder.forsale_type) {
        case 'contact':
          saleSettings.forsale_template = updatedFolder.forsale_template
          saleSettings.forsale_linkstyle = updatedFolder.forsale_linkstyle
          saleSettings.custom_text = updatedFolder.custom_text
          saleSettings.min_offer = updatedFolder.min_offer
          saleSettings.currency = updatedFolder.currency
          saleSettings.captcha = updatedFolder.captcha.toString()
          break
        case 'externallink':
          saleSettings.sellurl = updatedFolder.sellurl
          saleSettings.forsale_linkstyle = updatedFolder.forsale_linkstyle
          saleSettings.custom_text = updatedFolder.custom_text
          break
      }

      return saleSettings
    },
    backToRoute() {
      if (this.routeOnAction !== null) {
        this.$router.push(this.routeOnAction)
      }
    }
  }
}
</script>
